export default {
  'layout.header.vietnamese': 'Vietnamese',
  'layout.header.english': 'English',
  'layout.header.select_lang': 'Select language',
  'layout.header.logout': 'Logout',

  'layout.page_title.homepage': 'Dashboard',
  'layout.page_title.product': 'Product Management',
  'layout.page_title.create_product': 'Create product',
  'layout.page_title.update_product': 'Update product',
  'layout.page_title.category': 'Category management',
  'layout.page_title.create_category': 'Create category',
  'layout.page_title.update_category': 'Update category',
  'layout.page_title.blog': 'Blog management',
  'layout.page_title.create_blog': 'Create blog',
  'layout.page_title.update_blog': 'Update blog',
  'layout.page_title.policy': 'Policy management',
  'layout.page_title.update_policy': 'Update policy',
  'layout.page_title.create_policy': 'Create policy',
  'layout.page_title.order': 'Order management',
  'layout.page_title.update_order': 'Update order',
  'layout.page_title.brand': 'Brand management',
  'layout.page_title.tag': 'Tag management',
  'layout.page_title.login': 'Login',
  'layout.page_title.shopinfo': 'Store information',
  'layout.page_title.website': 'Website information',
  'layout.page_title.code_plugin': 'Plugins management',

  'layout.homepage.select_time': 'Select time',
  'layout.homepage.view_statistic': 'View statistics',
  'layout.homepage.statistic_in_month': 'Statistics about number of access in {time}',
  'layout.homepage.num_of_access': 'Number of access',
  'layout.homepage.orders': 'Orders',
  'layout.homepage.products': 'Products',
  'layout.homepage.categories': 'Categories',
  'layout.homepage.brands': 'Brands',
  'layout.homepage.policies': 'Policies',
  'layout.homepage.tags': 'Tags',
  'layout.homepage.blogs': 'Blogs',

  'layout.order_page.search_order': 'Search order',
  'layout.order_page.customer_name_phone_email': 'Name/phone/email',
  'layout.order_page.from_date': 'From date',
  'layout.order_page.to_date': 'To date',
  'layout.order_page.status': 'Status',
  'layout.order_page.sort_by': 'Sort by',
  'layout.order_page.order_list': 'Order list',
  'layout.order_page.phone': 'Phone',
  'layout.order_page.email': 'Email',
  'layout.order_page.time': 'Time',
  'layout.order_page.address': 'Address',
  'layout.order_page.customer': 'Customer',
  'layout.order_page.customer_note': "Customer's note",
  'layout.order_page.shop_note': "Shop's note",
  'layout.order_page.payment_method': 'Payment method',
  'layout.order_page.shipping_money': 'Shipping money',
  'layout.order_page.product_price': 'Product price',
  'layout.order_page.order_total': 'Order total',
  'layout.order_page.total': 'Total',
  'layout.order_page.search': 'Search',
  'layout.order_page.order_details': 'Order details',
  'layout.order_page.product': 'Product',
  'layout.order_page.unit_price': 'Unit price',
  'layout.order_page.information': 'Information',
  'layout.order_page.image': 'Image',
  'layout.order_page.quantity': 'Quantity',

  'layout.product_page.filter_product': 'Filter products',
  'layout.product_page.create_new': 'Create new product',
  'layout.product_page.search_product': 'Search products...',
  'layout.product_page.product': 'Product',
  'layout.product_page.category': 'Category',
  'layout.product_page.tag': 'Tag',
  'layout.product_page.brand': 'Brand',
  'layout.product_page.sort_by': 'Sort by',
  'layout.product_page.sort_index': 'Sort index',
  'layout.product_page.save_sort_index': 'Save sort index',
  'layout.product_page.product_list': 'Product list',
  'layout.product_page.name': 'Name',
  'layout.product_page.image': 'Image',
  'layout.product_page.old_price': 'Old price',
  'layout.product_page.new_price': 'New price',
  'layout.product_page.hot': 'Hot/best selling',
  'layout.product_page.available': 'Available',
  'layout.product_page.create_new_product': 'Create new product',
  'layout.product_page.product_info': 'Product information',
  'layout.product_page.product_type': 'Product type',
  'layout.product_page.add_product_type': 'Add product type',
  'layout.product_page.price': 'Price',
  'layout.product_page.main_image': 'Main image',
  'layout.product_page.sub_image': 'Sub image',
  'layout.product_page.select_tag': 'Select tag',

  'layout.category_page.create_new': 'Create new category',
  'layout.category_page.input_category': 'Input category...',
  'layout.category_page.category': 'Category',
  'layout.category_page.image': 'Image',
  'layout.category_page.sort_index': 'Sort index',
  'layout.category_page.belong_to_category': 'Belong to category',
  'layout.category_page.category_list': 'Category list',
  'layout.category_page.search_category': 'Search category',

  'layout.brand_page.create_new': 'Create new brand',
  'layout.brand_page.update_brand': 'Update brand',
  'layout.brand_page.input_brand': 'Input brand...',
  'layout.brand_page.brand': 'Brand',
  'layout.brand_page.belong_to_brand': 'Belong to brand',
  'layout.brand_page.sort_index': 'Sort index',
  'layout.brand_page.brand_list': 'Brand list',
  'layout.brand_page.search_brand': 'Search brand',

  'layout.shop_page.email': 'Email',
  'layout.shop_page.phone': 'Phone',
  'layout.shop_page.shop_name': 'Store name',
  'layout.shop_page.fb_link': 'Link Facebook',
  'layout.shop_page.zalo_link': 'Link Zalo',
  'layout.shop_page.youtube_link': 'Link Youtube',
  'layout.shop_page.instagram_link': 'Link Instagram',
  'layout.shop_page.address': 'Address',
  'layout.shop_page.google_map_address_link': 'Google map link address',
  'layout.shop_page.website_link': 'Link website',
  'layout.shop_page.about_us': 'Store information (about us)',
  'layout.shop_page.logo': 'Logo image',

  'layout.blog_page.create_new': 'Create new blog',
  'layout.blog_page.search_blog': 'Search blog',
  'layout.blog_page.title': 'Title',
  'layout.blog_page.image': 'Image',
  'layout.blog_page.short_desc': 'Short introduction',
  'layout.blog_page.content': 'Content',
  'layout.blog_page.sort_index': 'Sort index',
  'layout.blog_page.blog_list': 'Blog list',

  'layout.policy_page.create_new': 'Create new policy',
  'layout.policy_page.search_policy': 'Search policy',
  'layout.policy_page.policy_name': "Policy's name",
  'layout.policy_page.content': 'Content',
  'layout.policy_page.policy_list': 'Policy list',

  'layout.website_page.home_page_image': 'Homepage banner images',
  'layout.website_page.sub_page_image': 'Subpage banner images',
  'layout.website_page.customer_partner_image': 'Customer partner images',
  'layout.website_page.logo_image_website': 'Logo website image',
  'layout.website_page.addthis_social_plugin': 'Plugin Addthis',

  'layout.tag_page.create_new': 'Create new tag',
  'layout.tag_page.input_tag': 'Input tag...',
  'layout.tag_page.tag': 'Tag',
  'layout.tag_page.update_tag': 'Update tag',
  'layout.tag_page.logo_image_website': 'Logo website',
  'layout.tag_page.tag_list': 'Tag list',
  'layout.tag_page.search_tag': 'Search tag',

  'layout.code_plugin_page.filter_plugin': 'Filter plugins',
  'layout.code_plugin_page.create_new': 'Create new plugin',
  'layout.code_plugin_page.input_code_plugin': 'Input code...',
  'layout.code_plugin_page.code_plugin': 'Plugin',
  'layout.code_plugin_page.update_code_plugin': 'Update plugin',
  'layout.code_plugin_page.code_plugin_list': 'Plugins list',
  'layout.code_plugin_page.search_code_plugin': 'Search plugin',
  'layout.code_plugin_page.name': 'Plugin name',
  'layout.code_plugin_page.code': 'Plugin code',
  'layout.code_plugin_page.code_position': 'Plugin code\'s position',
  'layout.code_plugin_page.installed_on_web': 'Installed on web',
  'layout.code_plugin_page.sort_index': 'Sort index',
  'layout.code_plugin_page.head_tag': 'Head tag',
  'layout.code_plugin_page.body_tag': 'Body tag',

  'layout.login_page.username': 'Username',
  'layout.login_page.password': 'Password',

  'layout.sidebar.homepage': 'DASHBOARD',
  'layout.sidebar.order': 'ORDERS',
  'layout.sidebar.product': 'PRODUCTS',
  'layout.sidebar.category': 'CATEGORIES',
  'layout.sidebar.brand': 'BRANDS',
  'layout.sidebar.shop': 'STORE INFO',
  'layout.sidebar.blog': 'BLOGS',
  'layout.sidebar.policy': 'POLICIES',
  'layout.sidebar.website': 'WEBSITE',
  'layout.sidebar.tag': 'TAG',

  'layout.seo.seo_content': 'SEO content',
  'layout.seo.title': 'Title',
  'layout.seo.keyword': 'keywords',
  'layout.seo.description': 'Description',
  'layout.seo.title_hint': 'The content of Title meta tag should be 50 - 60 characters to optimize SEO ({numOfChars} characters)',
  'layout.seo.keyword_hint': 'The content of Keyword meta tag should be 100 - 255 characters to optimize SEO ({numOfChars} characters)',
  'layout.seo.description_hint': 'The content of Description meta tag should be 50 - 160 characters to optimize SEO ({numOfChars} characters)',
  'layout.seo.title_tooltip': 'The content of Title meta tag to optimize SEO',
  'layout.seo.keyword_tooltip': 'Main keywords for the page',
  'layout.seo.description_tooltip': 'The content of Description meta tag to optimize SEO',

  'layout.action.cancel': 'Cancel',
  'layout.action.reset': 'Reset',
  'layout.action.ok': 'Ok',
  'layout.action.save': 'Save',
  'layout.action.delete': 'Delete',
  'layout.action.update': 'Update',
  'layout.action.save_sort_index': 'Save sort index',
  'layout.action.search': 'Search',
  'layout.action.preview_on_web': 'Preview on web',
  'layout.action.view_details': 'View details',
  'layout.action.update_sitemap': 'Update sitemap',
  'layout.action.login': 'Login',
  'layout.action.add_image': 'Add more image',
  'layout.action.go_back': 'Go back',

  'layout.common.sort_index': 'Sort index',
  'layout.common.all': 'All',
  'layout.common.information': 'Information',
  'layout.common.show_on_web': 'Show on web',

  'layout.order_status.all': 'All',
  'layout.order_status.new': 'New',
  'layout.order_status.contacting': 'Contacting',
  'layout.order_status.delivering': 'Delivering',
  'layout.order_status.cancel': 'Canceled',
  'layout.order_status.done': 'Done successfully',
  'layout.order_status.undefined': 'Undefined',

  'layout.sort_by.created_at_desc': 'Newest',
  'layout.sort_by.created_at_asc': 'Oldest',
  'layout.sort_by.sort_index_asc': 'Smallest sort index',
  'layout.sort_by.sort_index_desc': 'Largest sort index',

  'layout.statistic.product.in_stock': 'In Stock',
  'layout.statistic.product.out_of_stock': 'Out Of Stock',
  'layout.statistic.product.hot': 'BestSelling',
  'layout.statistic.product.discount': 'Discount',
}
