import {
  HomeOutlined,
  ShoppingCartOutlined,
  CarOutlined,
  PartitionOutlined,
  ShopOutlined,
  ReadOutlined,
  FormOutlined,
  GlobalOutlined,
  VideoCameraOutlined,
  FilterOutlined,
  FacebookOutlined,
} from "@ant-design/icons";

const Icons = {
  HomeIcon: HomeOutlined,
  OrderIcon: ShoppingCartOutlined,
  ProductIcon: CarOutlined,
  CategoryIcon: PartitionOutlined,
  BrandIcon: PartitionOutlined,
  ShopIcon: ShopOutlined,
  BlogIcon: ReadOutlined,
  PolicyIcon: FormOutlined,
  WebsiteIcon: GlobalOutlined,
  TagIcon: VideoCameraOutlined,
  FilterIcon: FilterOutlined,
  SocialPluginIcon: FacebookOutlined,
}

export default Icons;
