export default {
  'message.add_success': 'Thêm thành công!',
  'message.update_success': 'Cập nhật thành công!',
  'message.delete_success': 'Xóa thành công!',
  'message.add_fail': 'Không thể thêm, vui lòng kiểm tra lại!',
  'message.update_fail': 'Không thể cập nhật, vui lòng kiểm tra lại!',
  'message.delete_fail': 'Không thể xóa, vui lòng kiểm tra lại!',
  'message.server_error': 'Đã có lỗi xảy ra, vui lòng kiểm tra lại hệ thống!',
  'message.confirm_delete': 'Đồng ý xóa!',
  'message.ok_button_text': 'Đồng ý',
  'message.cancel_button_text': 'Hủy',
  'message.file_oversize': 'File upload không quá 0.5MB!',
  'message.confirm_logout': 'Bạn có chắc muốn đăng xuất khỏi ứng dụng!',
  'message.login_success': 'Đăng nhập thành công!',
  'message.login_fail': 'Đăng nhập thất bại! Vui lòng kiểm tra tên đăng nhập & mật khẩu!',
  'message.invalid_info': 'Thông tin không hợp lệ!',
  'message.main_image_required': 'Chưa upload ảnh chính!',
  'message.category_or_brand_required': 'Sản phẩm chưa có danh mục và hãng xe!',
  'message.price_required': 'Giá sản phẩm phải là số lớn hơn 0!',
  'message.logo_website_required': 'Ảnh logo website chưa upload!',
  'message.sub_page_image_required': 'Ảnh banner trang con chưa upload!',
  'message.customer_image_validation': 'Số lượng Ảnh đối tác khách hàng cần upload là 1 - 30!',
  'message.username_required': 'Vui lòng nhập tên đăng nhập!',
  'message.passwrod_required': 'Vui lòng nhập mật khẩu!',
  'message.image_required': 'Vui lòng upload ảnh!',
  'message.notfound_subtitle': 'Trang này không tồn tại, vui lòng quay lại trang chủ',
  'message.fail_to_load_due_to_lost_connection': 'Không thể tải trang do kết nổi mạng không ổn định, vui lòng kiểm tra kết nói mạng!',
  'message.lost_connection': 'Mất kêt nối mạng!',
  'message.name_required': 'Vui lòng nhập tên!',
  'message.plugin_code_position_required': 'Vui lòng chọn vị trí gắn code!',
  'message.plugin_code_required': 'Vui lòng nhập mã code!',

  'message.category_notfound': 'Không tìm thấy danh mục này!',
  'message.store_info_notfound': 'Không tìm thấy thông tin cửa hàng trên hệ thống!',
  'message.blog_notfound': 'Không tìm thấy bài viết này!',
  'message.policy_notfound': 'Không tìm thấy chính sách này!',
  'message.website_notfound': 'Không tìm thấy thông tin website trên hệ thống!',
  'message.product_notfound': 'Không tìm thấy sản phẩm này!',
  'message.order_notfound': 'Không tìm thấy đơn hàng này!',
  'message.plugin_notfound': 'Không tìm thấy plugin này!',
}
