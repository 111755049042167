import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Button } from 'antd';

import {
  setOpenSidebar as setOpenSidebarAction
} from '@/store/layout/actions';

import { setAuthHeader } from '@/config/auth';
import i18n from '@/config/locales';
import { ROUTE_VALUES, ROUTE_VALUES_MAP } from '@/config/routes/routes';
import { clearToken } from '@/config/auth/storage';
import { isEmpty } from '@/common/utils/validation';
import { scrollWindowToTop } from '@/common/utils/utils';
import { APP_NAME, APP_RELEASE_DATE } from '@/common/constants/app';
import { useWindow } from '@/hooks';

import showConfirmModal from '@/components/StandardModal/ConfirmModal';
import { Icons } from '@/components';

import { PageBreadcrumb, Sidebar, ToggleSidebarButton, SelectLang, } from './components';

import './index.css';

const { Header, Content, Footer } = Layout;

const menuItems = [
  {
    route: ROUTE_VALUES.dashboardRoute,
    title: i18n.t('layout.sidebar.homepage'),
    icon: <Icons.HomeIcon />,
  },
  {
    route: ROUTE_VALUES.orderRoute,
    title: i18n.t('layout.sidebar.order'),
    icon: <Icons.OrderIcon />,
  },
  {
    route: ROUTE_VALUES.productRoute,
    title: i18n.t('layout.sidebar.product'),
    icon: <Icons.ProductIcon />,
  },
  {
    route: ROUTE_VALUES.categoryRoute,
    title: i18n.t('layout.sidebar.category'),
    icon: <Icons.CategoryIcon />,
  },
  {
    route: ROUTE_VALUES.brandRoute,
    title: i18n.t('layout.sidebar.brand'),
    icon: <Icons.BrandIcon />,
  },
  {
    route: ROUTE_VALUES.shopRoute,
    title: i18n.t('layout.sidebar.shop'),
    icon: <Icons.ShopIcon />,
  },
  {
    route: ROUTE_VALUES.blogRoute,
    title: i18n.t('layout.sidebar.blog'),
    icon: <Icons.BlogIcon />,
  },
  {
    route: ROUTE_VALUES.policyRoute,
    title: i18n.t('layout.sidebar.policy'),
    icon: <Icons.PolicyIcon />,
  },
  {
    route: ROUTE_VALUES.websiteRoute,
    title: i18n.t('layout.sidebar.website'),
    icon: <Icons.WebsiteIcon />,
  },
  {
    route: ROUTE_VALUES.tagRoute,
    title: i18n.t('layout.sidebar.tag'),
    icon: <Icons.TagIcon />,
  },
  {
    route: ROUTE_VALUES.socialPluginRoute,
    title: 'SOCIAL PLUGINS',
    icon: <Icons.SocialPluginIcon />,
  },
];


const DashboardLayout = (props) => {

  const {
    children
  } = props;

  const openSidebar = useSelector(state => state.layout.openSidebar);
  const dispatch = useDispatch();
  const setOpenSidebar = (payload) => dispatch(setOpenSidebarAction(payload));

  const history = useHistory();
  const location = useLocation();

  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [isMobileScreen, setIsMobileScreen] = useState(true);

  const windowSize = useWindow();

  const { t: i18nTranslate } = useTranslation();

  const toggle = () => {
    setOpenSidebar(!openSidebar);
  };

  const getBreadcrumbItems = () => {
    const breadCrumbItems = [menuItems[0]];
    const pathItems = location.pathname.split('/').filter(it => it);

    if (pathItems.length > 0) {
      const routeMenu = menuItems.find(it => it.route === '/' + pathItems[0]);
      if (!isEmpty(routeMenu) && routeMenu.route !== ROUTE_VALUES.dashboardRoute) {
        breadCrumbItems.push(routeMenu);
      }

      if (pathItems[1]) {
        const formattedPathname = `/${pathItems[0]}/${pathItems[1]}`;
        breadCrumbItems.push({
          route: location.pathname,
          title: ROUTE_VALUES_MAP[formattedPathname].title,
        })
      }
    }
    return breadCrumbItems;
  }

  const handleLogout = () => {
    showConfirmModal({
      title: i18nTranslate('message.confirm_logout'),
      onOk: () => {
        setAuthHeader(null);
        clearToken();
        history.push(ROUTE_VALUES.loginRoute)
      },
      onCancel: () => { }
    })
  }

  useEffect(() => {
    if (windowSize.width && windowSize.width < 900) {
      setIsMobileScreen(true);
      setOpenSidebar(false);
    } else {
      setIsMobileScreen(false);
      setOpenSidebar(true);
    }
  }, [windowSize]);

  useEffect(() => {
    scrollWindowToTop();

    setBreadcrumbItems(getBreadcrumbItems());
  }, [location.pathname]);


  return (
    <Layout>
      <Sidebar
        menuItems={menuItems}
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        isMobileScreen={isMobileScreen}
      />
      <Layout className={classnames('site-layout', { 'ml-250': !isMobileScreen && openSidebar, 'ml-80': !isMobileScreen && !openSidebar })}>
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <ToggleSidebarButton onClick={toggle} />
          <Button className="float-right mt-3 mr-3" onClick={handleLogout}>{i18nTranslate('layout.header.logout')}</Button>
          <SelectLang />
        </Header>
        <div className="ml-3 mt-2">
          <PageBreadcrumb breadCrumbItems={breadcrumbItems} />
        </div>
        <Content className="site-layout-background site-content-layout">
          {children}
        </Content>
        <Footer style={{ textAlign: 'center' }}>{APP_NAME} ©{APP_RELEASE_DATE}</Footer>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
