import React from 'react';
import i18n from '@/config/locales';

// import ProductPage from '@/pages/product';
// import OrderPage from '@/pages/order';
// import DashboardPage from '@/pages/dashboard';
// import CategoryPage from '@/pages/category';
// import CategoryDetailsPage from '@/pages/category/CategoryDetailsPage';
// import BlogPage from '@/pages/blog';
// import BlogDetailsPage from '@/pages/blog/BlogDetailsPage';
// import BrandPage from '@/pages/brand';
// import ShopPage from '@/pages/shop';
// import ProductDetailsPage from '@/pages/product/ProductDetailsPage';
// import NotfoundPage from '@/pages/notfound';
// import LoginPage from '@/pages/user/LoginPage';
// import WebsitePage from '@/pages/website';
// import OrderDetailsPage from '@/pages/order/OrderDetailsPage';
// import PolicyPage from '@/pages/policy';
// import PolicyDetailsPage from '@/pages/policy/PolicyDetailsPage';
// import TagPage from '@/pages/tag';

// import {
//   ProductPage,
//   ProductDetailsPage,
//   OrderPage,
//   OrderDetailsPage,
//   DashboardPage,
//   CategoryPage,
//   CategoryDetailsPage,
//   BlogPage,
//   BlogDetailsPage,
//   BrandPage,
//   ShopPage,
//   WebsitePage,
//   PolicyPage,
//   PolicyDetailsPage,
//   TagPage,
//   LoginPage,
//   NotfoundPage
// } from '@/pages';

const ProductPage = React.lazy(() => import('@/pages/product'));
const ProductDetailsPage = React.lazy(() => import('@/pages/product/ProductDetailsPage'));
const OrderPage = React.lazy(() => import('@/pages/order'));
const OrderDetailsPage = React.lazy(() => import('@/pages/order/OrderDetailsPage'));
const DashboardPage = React.lazy(() => import('@/pages/dashboard'));
const CategoryPage = React.lazy(() => import('@/pages/category'));
const CategoryDetailsPage = React.lazy(() => import('@/pages/category/CategoryDetailsPage'));
const BlogPage = React.lazy(() => import('@/pages/blog'));
const BlogDetailsPage = React.lazy(() => import('@/pages/blog/BlogDetailsPage'));
const BrandPage = React.lazy(() => import('@/pages/brand'));
const ShopPage = React.lazy(() => import('@/pages/shop'));
const WebsitePage = React.lazy(() => import('@/pages/website'));
const PolicyPage = React.lazy(() => import('@/pages/policy'));
const PolicyDetailsPage = React.lazy(() => import('@/pages/policy/PolicyDetailsPage'));
const TagPage = React.lazy(() => import('@/pages/tag'));
const LoginPage = React.lazy(() => import('@/pages/user/LoginPage'));
const NotfoundPage = React.lazy(() => import('@/pages/notfound'));
const SocialPluginPage = React.lazy(() => import('@/pages/socialPlugin'));
const SocialPluginDetailsPage = React.lazy(() => import('@/pages/socialPlugin/SocialPluginDetailsPage'));


export const ROUTE_VALUES = {
  indexRoute: '/',
  dashboardRoute: '/dashboard',
  orderRoute: '/order',
  orderEditRoute: '/order/edit',
  websiteRoute: '/website',
  productRoute: '/product',
  productEditRoute: "/product/edit",
  createProductRoute: "/product/create",
  blogRoute: '/blog',
  blogCreateRoute: '/blog/create',
  blogEditRoute: '/blog/edit',
  categoryRoute: '/category',
  categoryEditRoute: '/category/edit',
  categoryCreateRoute: '/category/create',
  brandRoute: '/brand',
  shopRoute: '/shop',
  policyRoute: '/policy',
  policyCreateRoute: '/policy/create',
  policyEditRoute: '/policy/edit',
  tagRoute: '/tag',
  loginRoute: '/login',
  socialPluginRoute: '/social-plugin',
  socialPluginEditRoute: '/social-plugin/edit',
  socialPluginCreateRoute: '/social-plugin/create',
};

export const ROUTE_VALUES_MAP = {
  [ROUTE_VALUES.indexRoute]: { title: i18n.t('layout.page_title.homepage') },
  [ROUTE_VALUES.dashboardRoute]: { title: i18n.t('layout.page_title.homepage') },
  [ROUTE_VALUES.orderRoute]: { title: i18n.t('layout.page_title.order') },
  [ROUTE_VALUES.orderEditRoute]: { title: i18n.t('layout.page_title.update_order') },
  [ROUTE_VALUES.websiteRoute]: { title: i18n.t('layout.page_title.website') },
  [ROUTE_VALUES.productRoute]: { title: i18n.t('layout.page_title.product') },
  [ROUTE_VALUES.productEditRoute]: { title: i18n.t('layout.page_title.update_product') },
  [ROUTE_VALUES.createProductRoute]: { title: i18n.t('layout.page_title.create_product') },
  [ROUTE_VALUES.blogRoute]: { title: i18n.t('layout.page_title.blog') },
  [ROUTE_VALUES.blogCreateRoute]: { title: i18n.t('layout.page_title.create_blog') },
  [ROUTE_VALUES.blogEditRoute]: { title: i18n.t('layout.page_title.update_blog') },
  [ROUTE_VALUES.categoryRoute]: { title: i18n.t('layout.page_title.category') },
  [ROUTE_VALUES.categoryEditRoute]: { title: i18n.t('layout.page_title.update_category') },
  [ROUTE_VALUES.categoryCreateRoute]: { title: i18n.t('layout.page_title.create_category') },
  [ROUTE_VALUES.brandRoute]: { title: i18n.t('layout.page_title.brand') },
  [ROUTE_VALUES.shopRoute]: { title: i18n.t('layout.page_title.shopinfo') },
  [ROUTE_VALUES.policyRoute]: { title: i18n.t('layout.page_title.policy') },
  [ROUTE_VALUES.policyCreateRoute]: { title: i18n.t('layout.page_title.create_policy') },
  [ROUTE_VALUES.policyEditRoute]: { title: i18n.t('layout.page_title.update_policy') },
  [ROUTE_VALUES.tagRoute]: { title: i18n.t('layout.page_title.tag') },
  [ROUTE_VALUES.loginRoute]: { title: i18n.t('layout.page_title.login') },
  [ROUTE_VALUES.socialPluginRoute]: { title: 'Quản lý Social Plugins' },
  [ROUTE_VALUES.socialPluginEditRoute]: { title: 'Cập nhật Social Plugin' },
  [ROUTE_VALUES.socialPluginCreateRoute]: { title: 'Tạo Social Plugin' },
}

export const routes = [
  {
    path: ROUTE_VALUES.indexRoute,
    component: DashboardPage,
    exact: true
  },
  {
    path: ROUTE_VALUES.dashboardRoute,
    component: DashboardPage,
    exact: true
  },
  {
    path: ROUTE_VALUES.orderRoute,
    component: OrderPage,
    exact: true
  },
  {
    path: `${ROUTE_VALUES.orderEditRoute}/:orderId`,
    component: OrderDetailsPage,
    exact: true
  },
  {
    path: ROUTE_VALUES.productRoute,
    component: ProductPage,
    exact: true
  },
  {
    path: `${ROUTE_VALUES.productEditRoute}/:productId`,
    component: ProductDetailsPage,
    exact: true
  },
  {
    path: ROUTE_VALUES.createProductRoute,
    component: ProductDetailsPage,
    exact: true
  },
  {
    path: ROUTE_VALUES.blogRoute,
    component: BlogPage,
    exact: true
  },
  {
    path: ROUTE_VALUES.blogCreateRoute,
    component: BlogDetailsPage,
    exact: true
  },
  {
    path: `${ROUTE_VALUES.blogEditRoute}/:blogId`,
    component: BlogDetailsPage,
    exact: true
  },
  {
    path: ROUTE_VALUES.categoryRoute,
    component: CategoryPage,
    exact: true
  },
  {
    path: ROUTE_VALUES.categoryCreateRoute,
    component: CategoryDetailsPage,
    exact: true
  },
  {
    path: `${ROUTE_VALUES.categoryEditRoute}/:categoryId`,
    component: CategoryDetailsPage,
    exact: true
  },
  {
    path: ROUTE_VALUES.brandRoute,
    component: BrandPage,
    exact: true
  },
  {
    path: ROUTE_VALUES.shopRoute,
    component: ShopPage,
    exact: true
  },

  //website routes
  {
    path: ROUTE_VALUES.websiteRoute,
    component: WebsitePage,
    exact: true,
  },

  //policy routes
  {
    path: ROUTE_VALUES.policyRoute,
    component: PolicyPage,
    exact: true,
  },
  {
    path: ROUTE_VALUES.policyCreateRoute,
    component: PolicyDetailsPage,
    exact: true,
  },
  {
    path: ROUTE_VALUES.policyEditRoute + "/:policyId",
    component: PolicyDetailsPage,
    exact: true,
  },

   //social plugin routes
   {
    path: ROUTE_VALUES.socialPluginRoute,
    component: SocialPluginPage,
    exact: true,
  },
  {
    path: ROUTE_VALUES.socialPluginCreateRoute,
    component: SocialPluginDetailsPage,
    exact: true,
  },
  {
    path: ROUTE_VALUES.socialPluginEditRoute + "/:socialPluginId",
    component: SocialPluginDetailsPage,
    exact: true,
  },

  //tag routes
  {
    path: ROUTE_VALUES.tagRoute,
    component: TagPage,
    exact: true,
  },
  {
    path: ROUTE_VALUES.loginRoute,
    component: LoginPage,
    exact: true,
    hideLayout: true,
    public: true
  },
  {
    path: ROUTE_VALUES.websiteRoute,
    component: WebsitePage,
    exact: true,
  },
  {
    component: NotfoundPage,
  },
];
