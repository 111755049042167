import { ROOT_WEB } from "@/common/constants/app";
import { isEmpty } from "./validation";

export const formatUrlWithRootWeb = (url) => {
  if (!isEmpty(url) && url.indexOf(ROOT_WEB) > -1) return url;
  return `${ROOT_WEB}${url}`;
}

export const openNewTab = (url) => {
  const redirectLink = formatUrlWithRootWeb(url);
  window.open(redirectLink, '_blank');
}

export const scrollWindowToTop = () => {
  window.scrollTo(0, 0);
}
