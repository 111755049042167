import { FMANRACING_JWT_TOKEN_KEY } from "@/common/constants/auth";

export const getToken = () => {
  return localStorage.getItem(FMANRACING_JWT_TOKEN_KEY);
}

export const saveToken = (token) => {
  localStorage.setItem(FMANRACING_JWT_TOKEN_KEY, token);
}

export const clearToken = () => {
  localStorage.removeItem(FMANRACING_JWT_TOKEN_KEY);
}
