import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu, Layout, Drawer } from 'antd';

import { ROOT_WEB } from '@/common/constants/app';

import './styles.css';

const { Sider } = Layout;

const SIDEBAR_WIDTH = 250;

function Sidebar(props) {
  const {
    openSidebar,
    menuItems,
    setOpenSidebar,
    isMobileScreen
  } = props;

  const history = useHistory();
  const location = useLocation();

  const gotoRoute = (route) => {
    history.push(route);
  }

  const getSelectedKeys = () => {
    if (location.pathname === '/') return ['/dashboard']
    return menuItems.filter(item => location.pathname.indexOf(item.route) > -1).map((item) => item.route)
  }

  const selectedKeys = getSelectedKeys();

  const renderSiderMenu = () => {
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={isMobileScreen ? false : !openSidebar}
        className="sidebar"
        width={SIDEBAR_WIDTH}
      >
        <div className="logo" >
          <a href={ROOT_WEB} target='_blank'>
            <img
              className="img-fluid"
              src="/assets/img/logo/logo.jpg"
              alt=""
            />
          </a>
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
          {menuItems.map((item, i) => (
            <Menu.Item key={item.route} icon={item.icon} onClick={() => gotoRoute(item.route)}>
              {item.title}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
    )
  }

  return isMobileScreen ? (
    <Drawer
      visible={openSidebar}
      placement="left"
      onClose={() => setOpenSidebar(false)}
      closable={false}
      style={{
        padding: 0,
        height: '100vh',
      }}
      bodyStyle={{ padding: 0 }}
      width={SIDEBAR_WIDTH}
    >
      {renderSiderMenu()}
    </Drawer>
  ) : (<>{renderSiderMenu()}</>)
}

export default Sidebar;
