export default {
  'layout.header.vietnamese': 'Tiếng Việt',
  'layout.header.english': 'Tiếng Anh',
  'layout.header.select_lang': 'Chọn ngôn ngữ',
  'layout.header.logout': 'Đăng xuất',

  'layout.page_title.homepage': 'Trang chủ',
  'layout.page_title.product': 'Quản lý Sản phẩm',
  'layout.page_title.create_product': 'Tạo Sản phẩm',
  'layout.page_title.update_product': 'Cập nhật Sản phẩm',
  'layout.page_title.category': 'Quản lý Danh mục',
  'layout.page_title.create_category': 'Tạo Danh mục',
  'layout.page_title.update_category': 'Cập nhật Danh mục',
  'layout.page_title.blog': 'Quản lý Bài viết',
  'layout.page_title.create_blog': 'Tạo Bài viết',
  'layout.page_title.update_blog': 'Cập nhật Bài viết',
  'layout.page_title.policy': 'Quản lý Chính sách',
  'layout.page_title.update_policy': 'Cập nhật Chính sách',
  'layout.page_title.create_policy': 'Tạo Chính sách',
  'layout.page_title.order': 'Quản lý Đơn hàng',
  'layout.page_title.update_order': 'Cập nhật Đơn hàng',
  'layout.page_title.brand': 'Quản lý Hãng xe',
  'layout.page_title.tag': 'Quản lý tag',
  'layout.page_title.login': 'Đăng nhập',
  'layout.page_title.shopinfo': 'Thông tin cửa hàng',
  'layout.page_title.website': 'Thông tin website',
  'layout.page_title.code_plugin': 'Quản lý plugins',

  'layout.homepage.select_time': 'Chọn thời gian',
  'layout.homepage.view_statistic': 'Xem thống kê',
  'layout.homepage.statistic_in_month': 'Thống kê lượt truy cập tháng {time}',
  'layout.homepage.num_of_access': 'Lượt truy cập',
  'layout.homepage.orders': 'Đơn hàng',
  'layout.homepage.products': 'Sản phẩm',
  'layout.homepage.categories': 'Danh mục',
  'layout.homepage.brands': 'Hãng xe',
  'layout.homepage.policies': 'Chính sách',
  'layout.homepage.tags': 'Tags',
  'layout.homepage.blogs': 'Bài viết',

  'layout.order_page.search_order': 'Tìm đơn hàng',
  'layout.order_page.customer_name_phone_email': 'Tên/sdt/email',
  'layout.order_page.from_date': 'Từ ngày',
  'layout.order_page.to_date': 'Đến ngày',
  'layout.order_page.status': 'Trạng thái',
  'layout.order_page.sort_by': 'Sắp xếp theo',
  'layout.order_page.order_list': 'Danh sách đơn hàng',
  'layout.order_page.phone': 'SDT',
  'layout.order_page.email': 'Email',
  'layout.order_page.time': 'Thời gian',
  'layout.order_page.address': 'Địa chỉ',
  'layout.order_page.customer': 'Khách hàng',
  'layout.order_page.customer_note': 'Ghi chú của khách hàng',
  'layout.order_page.shop_note': 'Ghi chú của shop',
  'layout.order_page.payment_method': 'Phương thức thanh toán',
  'layout.order_page.shipping_money': 'Tiền ship',
  'layout.order_page.product_price': 'Tiền sản phẩm',
  'layout.order_page.order_total': 'Tổng đơn',
  'layout.order_page.total': 'Tổng',
  'layout.order_page.search': 'Tìm kiếm',
  'layout.order_page.order_details': 'Chi tiết đơn hàng',
  'layout.order_page.product': 'Sản phẩm',
  'layout.order_page.unit_price': 'Đơn giá',
  'layout.order_page.information': 'Thông tin',
  'layout.order_page.image': 'Ảnh',
  'layout.order_page.quantity': 'Số lượng',

  'layout.product_page.filter_product': 'Lọc sản phẩm',
  'layout.product_page.create_new': 'Tạo sản phẩm',
  'layout.product_page.search_product': 'Tìm sản phẩm...',
  'layout.product_page.product': 'Sản phẩm',
  'layout.product_page.category': 'Danh mục',
  'layout.product_page.tag': 'Tag',
  'layout.product_page.brand': 'Hãng xe',
  'layout.product_page.sort_by': 'Sắp xếp',
  'layout.product_page.sort_index': 'Thứ tự',
  'layout.product_page.save_sort_index': 'Luư thứ tự sắp xếp',
  'layout.product_page.product_list': 'Danh sách sản phẩm',
  'layout.product_page.name': 'Tên',
  'layout.product_page.image': 'Ảnh',
  'layout.product_page.old_price': 'Giá cũ',
  'layout.product_page.new_price': 'Giá mới',
  'layout.product_page.hot': 'Nổi bật',
  'layout.product_page.available': 'Còn hàng',
  'layout.product_page.create_new_product': 'Tạo sản phẩm mới',
  'layout.product_page.product_info': 'Thông tin sản phẩm',
  'layout.product_page.product_type': 'Loại sản phẩm',
  'layout.product_page.add_product_type': 'Thêm loại sản phẩm',
  'layout.product_page.price': 'Giá',
  'layout.product_page.main_image': 'Ảnh chính',
  'layout.product_page.sub_image': 'Ảnh phụ',
  'layout.product_page.select_tag': 'Chọn tag',

  'layout.category_page.create_new': 'Tạo danh mục mới',
  'layout.category_page.input_category': 'Nhập danh mục...',
  'layout.category_page.category': 'Danh mục',
  'layout.category_page.image': 'Ảnh',
  'layout.category_page.sort_index': 'Thứ tự',
  'layout.category_page.belong_to_category': 'Thuộc danh mục',
  'layout.category_page.category_list': 'Danh sách danh mục',
  'layout.category_page.search_category': 'Tìm danh mục',

  'layout.brand_page.create_new': 'Tạo hãng xe mới',
  'layout.brand_page.update_brand': 'Cập nhật hãng xe',
  'layout.brand_page.input_brand': 'Nhập hãng xe...',
  'layout.brand_page.brand': 'Hãng xe',
  'layout.brand_page.belong_to_brand': 'Thuộc hãng xe',
  'layout.brand_page.sort_index': 'Thứ tự',
  'layout.brand_page.brand_list': 'Danh sách hãng xe',
  'layout.brand_page.search_brand': 'Tìm hãng xe',

  'layout.shop_page.email': 'Email',
  'layout.shop_page.phone': 'SĐT',
  'layout.shop_page.shop_name': 'Tên của hàng',
  'layout.shop_page.fb_link': 'Link Facebook',
  'layout.shop_page.zalo_link': 'Link Zalo',
  'layout.shop_page.youtube_link': 'Link Youtube',
  'layout.shop_page.instagram_link': 'Link Instagram',
  'layout.shop_page.address': 'Địa chỉ',
  'layout.shop_page.google_map_address_link': 'Link địa chỉ google map',
  'layout.shop_page.website_link': 'Link website',
  'layout.shop_page.about_us': 'Thông tin mô tả (about us)',
  'layout.shop_page.logo': 'Ảnh logo',

  'layout.blog_page.create_new': 'Tạo bài viết mới',
  'layout.blog_page.search_blog': 'Tìm bài viết',
  'layout.blog_page.title': 'Tiêu đề',
  'layout.blog_page.image': 'Ảnh',
  'layout.blog_page.short_desc': 'Nội dung giới thiệu',
  'layout.blog_page.content': 'Nội dung',
  'layout.blog_page.sort_index': 'Thứ tự',
  'layout.blog_page.blog_list': 'Danh sách bài viết',

  'layout.policy_page.create_new': 'Tạo mới chính sách',
  'layout.policy_page.search_policy': 'Tìm chính sách',
  'layout.policy_page.policy_name': 'Tên chính sách',
  'layout.policy_page.content': 'Nội dung',
  'layout.policy_page.policy_list': 'Danh sách chính sách',

  'layout.website_page.home_page_image': 'Ảnh banner trang chủ',
  'layout.website_page.sub_page_image': 'Ảnh banner trang con',
  'layout.website_page.customer_partner_image': 'Ảnh đối tác khách hàng',
  'layout.website_page.logo_image_website': 'Ảnh logo website',
  'layout.website_page.addthis_social_plugin': 'Plugin Addthis',

  'layout.tag_page.create_new': 'Tạo tag mới',
  'layout.tag_page.input_tag': 'Nhập tag...',
  'layout.tag_page.tag': 'Tag',
  'layout.tag_page.update_tag': 'Cập nhật tag',
  'layout.tag_page.logo_image_website': 'Ảnh logo website',
  'layout.tag_page.tag_list': 'Danh sách tag',
  'layout.tag_page.search_tag': 'Tìm tag',

  'layout.code_plugin_page.filter_plugin': 'Lọc plugins',
  'layout.code_plugin_page.create_new': 'Tạo plugin mới',
  'layout.code_plugin_page.input_code_plugin': 'Nhập code...',
  'layout.code_plugin_page.code_plugin': 'Plugin',
  'layout.code_plugin_page.update_code_plugin': 'Cập nhật plugin',
  'layout.code_plugin_page.code_plugin_list': 'Danh sách plugins',
  'layout.code_plugin_page.search_code_plugin': 'Tìm plugin',
  'layout.code_plugin_page.name': 'Tên plugin',
  'layout.code_plugin_page.code': 'Mã code',
  'layout.code_plugin_page.code_position': 'Vị trí gắn code',
  'layout.code_plugin_page.installed_on_web': 'Cài đặt vào web',
  'layout.code_plugin_page.sort_index': 'Thứ tự',
  'layout.code_plugin_page.head_tag': 'Thẻ head của trang',
  'layout.code_plugin_page.body_tag': 'Thẻ body của trang',

  'layout.login_page.username': 'Tên đăng nhập',
  'layout.login_page.password': 'Mật khẩu',

  'layout.sidebar.homepage': 'TRANG CHỦ',
  'layout.sidebar.order': 'ĐƠN HÀNG',
  'layout.sidebar.product': 'SẢN PHẨM',
  'layout.sidebar.category': 'DANH MỤC',
  'layout.sidebar.brand': 'HÃNG XE',
  'layout.sidebar.shop': 'CỬA HÀNG',
  'layout.sidebar.blog': 'BÀI VIẾT',
  'layout.sidebar.policy': 'CHÍNH SÁCH',
  'layout.sidebar.website': 'WEBSITE',
  'layout.sidebar.tag': 'TAG',
  'layout.sidebar.social_plugin': 'SOCIAL PLUGIN',

  'layout.seo.seo_content': 'Nội dung SEO',
  'layout.seo.title': 'Nội dung SEO',
  'layout.seo.keyword': 'Từ khóa',
  'layout.seo.description': 'Mô tả',
  'layout.seo.title_hint': 'Nội dung thẻ meta Title trong khoảng 50 - 60 sẽ tối ưu SEO ({numOfChars} kí tự)',
  'layout.seo.keyword_hint': 'Nội dung thẻ meta Keywords trong khoảng 100 - 255 sẽ tối ưu SEO ({numOfChars} kí tự)',
  'layout.seo.description_hint': 'Nội dung thẻ meta Description trong khoảng 50 - 160 sẽ tối ưu SEO ({numOfChars} kí tự)',
  'layout.seo.title_tooltip': 'Nội dung thẻ meta Title dùng để SEO',
  'layout.seo.keyword_tooltip': 'Từ khóa chính cho trang',
  'layout.seo.description_tooltip': 'Nội dung thẻ meta Description dùng để SEO',

  'layout.action.cancel': 'Hủy',
  'layout.action.reset': 'Reset',
  'layout.action.ok': 'Đồng ý',
  'layout.action.save': 'Lưu',
  'layout.action.delete': 'Xóa',
  'layout.action.update': 'Cập nhật',
  'layout.action.save_sort_index': 'Lưu thứ tự',
  'layout.action.search': 'Tìm kiếm',
  'layout.action.preview_on_web': 'Xem trên web',
  'layout.action.view_details': 'Xem chi tiết',
  'layout.action.update_sitemap': 'Cập nhật sitemap',
  'layout.action.login': 'Đăng nhập',
  'layout.action.add_image': 'Thêm ảnh',
  'layout.action.go_back': 'Quay lại',

  'layout.common.sort_index': 'Thứ tự',
  'layout.common.all': 'Tất cả',
  'layout.common.information': 'Thông tin',
  'layout.common.show_on_web': 'Hiện trên web',

  'layout.order_status.all': 'Tất cả',
  'layout.order_status.new': 'Chờ xác nhận',
  'layout.order_status.contacting': 'Đang liên hệ',
  'layout.order_status.delivering': 'Đang giao',
  'layout.order_status.cancel': 'Đã bị hủy',
  'layout.order_status.done': 'Giao hàng thành công',
  'layout.order_status.undefined': 'Không xác định',

  'layout.sort_by.created_at_desc': 'Mới nhất',
  'layout.sort_by.created_at_asc': 'Cũ nhất',
  'layout.sort_by.sort_index_asc': 'STT nhỏ nhất',
  'layout.sort_by.sort_index_desc': 'STT lớn nhất',

  'layout.statistic.product.in_stock': 'Còn hàng',
  'layout.statistic.product.out_of_stock': 'Hết hàng',
  'layout.statistic.product.hot': 'Nổi bật',
  'layout.statistic.product.discount': 'Giảm giá',
}
